import PropTypes from "prop-types";

import useBase64SearchParam from "@lib/react-use/useBase64SearchParam";

import Genogram from "../genogram/WIPGenogram";
import RelationshipsTable from "./RelationshipsTable";

/** Component to toggle between displaying relationship information as a
 * table or as a genogram
 */
const RelationshipsTableOrGenogram = ({
  childAgencyHumanId,
  addPersonFormPath,
  downloadRelationshipsCsvPath,
}) => {
  const view = useBase64SearchParam("view");

  if (view === "genogram") {
    return <Genogram childAgencyHumanId={childAgencyHumanId} />;
  }

  return (
    <RelationshipsTable
      tab="relationships"
      addPersonFormPath={addPersonFormPath}
      keystoneAgencyHumanId={childAgencyHumanId}
      downloadRelationshipsCsvPath={downloadRelationshipsCsvPath}
    />
  );
};
RelationshipsTableOrGenogram.propTypes = {
  addPersonFormPath: PropTypes.string.isRequired,
  childAgencyHumanId: PropTypes.string.isRequired,
  downloadRelationshipsCsvPath: PropTypes.string.isRequired,
  ffAddPotentialConnections012024: PropTypes.bool,
};

export default RelationshipsTableOrGenogram;
