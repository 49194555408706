import { Flex } from "@heart/components";
import PropTypes from "prop-types";
import React from "react";

import SurfaceBase from "../surface/SurfaceBase";
import InputContainer from "./InputContainer";

/**
 * This is a Surface that contains inputs and its own set of
 * submit / cancel actions.  Each of these represents a self-
 * contained form that can be submitted independently of other
 * Surfaces on the page.
 *
 * Inputs are arranged in a single column, but feel free to wrap
 * them in an `<InputsRow>` if you want them to flow left-to-right
 * (they will wrap if the container is too small)
 */
const SurfaceForm = ({
  title,
  subtitle,
  collapsible,
  defaultCollapsed,
  children,
  onSubmit,
  actions,
  action,
  method,
  "data-testid": testId,
}) => (
  <SurfaceBase
    rootElement="form"
    rootElementProps={{ onSubmit, action, method }}
    title={title}
    subtitle={subtitle}
    collapsible={collapsible}
    defaultCollapsed={defaultCollapsed}
    data-testid={testId}
  >
    <Flex column gap="300">
      <InputContainer>{children}</InputContainer>
      {actions}
    </Flex>
  </SurfaceBase>
);

SurfaceForm.propTypes = {
  /** Title of the Surface, displayed in the top left. */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  /** Subtitle of the Surface, displayed in the top left */
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Whether the form should be collapsible using a toggle in the top right
   * corner of the surface
   */
  collapsible: PropTypes.bool,
  /** Whether the form should default to being collapsed on first load. Defaults
   * to false, and only usable in conjunction with `collapsible`
   */
  defaultCollapsed: PropTypes.bool,
  /** Put all the inputs as children without a container. */
  children: PropTypes.node.isRequired,
  /** Callback for mutating state when the form's inputs are all valid */
  onSubmit: PropTypes.func,
  /** Pass in an `<Actions />` instance here to specify submit / cancel behavior.
   * **Note**: Use the `onSubmit` prop of `<SurfaceForm>` rather than `<Action>`'s
   * `primaryAction`! */
  actions: PropTypes.node.isRequired,
  /** HTML `<form action="">` URL for where this form will be submitted */
  action: PropTypes.string,
  /** HTML `<form method="">` verb for where this form will be submitted */
  method: PropTypes.string,
  /** Test ID for Cypress or Jest */
  "data-testid": PropTypes.string,
};
export default SurfaceForm;
