import {
  FAMILY_FINDING_CONTACT_LOG_STATUS_ATTEMPTED,
  FAMILY_FINDING_CONTACT_LOG_STATUS_SUCCESSFUL,
} from "@root/constants";

const contactStatusMap = {
  [FAMILY_FINDING_CONTACT_LOG_STATUS_ATTEMPTED]: "alert",
  [FAMILY_FINDING_CONTACT_LOG_STATUS_SUCCESSFUL]: "success",
};

export default contactStatusMap;
