/** Conversion utilities for translating back and forth between
 * the way we're storing data in our DB and the way we display
 * inputs and information in the UI
 *
 * Our UI takes a more step by step approach to asking users for
 * information, whereas our DB only stores the information it
 * needs to extract this information for our UI.
 */
import { isEmpty, isNil, max, min } from "lodash";

import { translationWithRoot } from "@components/T";

import {
  mapConstantSetToValues,
  mapConstantToValue,
} from "@lib/constantsConversion";

import {
  RELATIVE,
  FICTIVE_KIN,
  PARTNER,
  ANCESTOR_KINSHIP_RELATIONSHIPS,
  DESCENDENT_KINSHIP_RELATIONSHIPS,
  KINSHIP_RELATIONSHIPS,
  PARTNER_STATUSES,
  PLACEMENT,
  NON_PLACEMENT,
  OTHER,
} from "@root/constants";

const { t } = translationWithRoot("activerecord.enums.relationships", {
  escapeJavascriptRoot: true,
});

export const parseRelationshipForUI = ({
  emotionalRelationshipStatuses = [],
  kinshipRelationship,
  parentalLine,
  lineageType,
  levelsOfSupport,
  ...relationshipDetails
}) => {
  /** In our UI, we split out the values for kinship relationship into three
   * different dropdowns, necessitating this shim to distinguish between values
   * for each
   */
  const partnerStatusForUI = PARTNER_STATUSES.includes(kinshipRelationship)
    ? mapConstantToValue({
        translationKey: "relationships.partner_status",
        value: kinshipRelationship,
      })
    : undefined;

  let kinshipRelationshipForUI;
  if (KINSHIP_RELATIONSHIPS.includes(kinshipRelationship)) {
    kinshipRelationshipForUI = mapConstantToValue({
      translationKey: "relationships.kinship_relationships",
      value: kinshipRelationship,
    });
  } else if (!isNil(partnerStatusForUI)) {
    kinshipRelationshipForUI = mapConstantToValue({
      translationKey: "relationships.kinship_relationships",
      value: PARTNER,
    });
  }

  const isRelative = [...KINSHIP_RELATIONSHIPS, ...PARTNER_STATUSES].includes(
    kinshipRelationship
  );
  const parsedKinshipRelationship = {
    relationshipCategory: isRelative
      ? { label: t("category.relative"), value: RELATIVE }
      : mapConstantToValue({
          translationKey: "relationships.category",
          value: kinshipRelationship,
        }),
    kinshipRelationship: kinshipRelationshipForUI,
    partnerStatus: partnerStatusForUI,
  };

  return {
    ...relationshipDetails,
    ...parsedKinshipRelationship,
    parentalLine: isNil(parentalLine)
      ? undefined
      : mapConstantSetToValues({
          constant: parentalLine.split("_and_"),
          translationKey: "relationships.parental_lines",
        }),
    emotionalRelationshipStatuses: mapConstantSetToValues({
      constant: emotionalRelationshipStatuses,
      translationKey: "relationships.emotional_status",
    }),
    lineageType: mapConstantToValue({
      translationKey: "relationships.lineage_type",
      value: lineageType,
    }),
    levelsOfSupport: Object.keys(levelsOfSupport || {}),
    levelsOfSupportOtherDescription: levelsOfSupport?.other?.details,
  };
};

export const relationshipsForUI = ({
  data: { agencyHumanRelationships = [] } = {},
  keystoneAgencyHumanId,
}) =>
  agencyHumanRelationships.reduce((parsedRelationships, relationship) => {
    const {
      sourceAgencyHuman: { id: sourceAgencyHumanId },
      destinationAgencyHuman: { id: destinationAgencyHumanId },
    } = relationship;

    /** The keystone agency human is the person whose relationships to each
     * child in the sibling group we're storing details for. As a result, we
     * want to key by the children's agency human ids as those are the unique
     * agency human ids across the relationships we're collectively editing
     *
     * It's possible for both ids to belong to children, in the case of siblings,
     * which is why we are using the terms keystone and non-keystone
     */
    const nonKeystoneAgencyHumanId =
      sourceAgencyHumanId.toString() === keystoneAgencyHumanId.toString()
        ? destinationAgencyHumanId
        : sourceAgencyHumanId;

    return {
      ...parsedRelationships,
      [nonKeystoneAgencyHumanId]: {
        nonKeystoneAgencyHumanId,
        keystoneAgencyHumanId,
        ...parseRelationshipForUI(relationship),
      },
    };
  }, {});

export const relationshipForDB = ({
  id,
  keystoneAgencyHumanId,
  nonKeystoneAgencyHumanId,
  emotionalRelationshipStatuses = [],
  relationshipCategory = {},
  kinshipRelationship = {},
  partnerStatus = {},
  parentalLine = [],
  lineageType = {},
  levelsOfSupport = [],
  levelsOfSupportOtherDescription,
  ...relationshipDetails
}) => {
  /** relationshipCategory, kinshipRelationship, and partnerStatus all roll up
   * into kinshipRelationship in our db entries
   */
  let kinshipRelationshipForDB = kinshipRelationship.value;
  if (isNil(kinshipRelationshipForDB)) {
    kinshipRelationshipForDB = relationshipCategory.value;
  } else if (kinshipRelationship.value === PARTNER) {
    kinshipRelationshipForDB = partnerStatus.value;
  }

  /** The expectation for this function is that the relationship is being defined as the
   * keystone agency human's relationship to the non-keystone agency human.
   *
   * This conversion functions on the expectation that we're documenting keystone agency
   * human's relationship to non-keystone agency human
   *
   * if kinshipRelationship is...
   * - a parent, grandparent, or aunt/uncle, keystone is the relative and non-keystone
   * is the child
   * - a child or niece/nephew, keystone is the child or niece/nephew of non-keystone relative
   * - fictive kin, keystone is fictive kin of the non-keystone child
   */
  let sourceAgencyHumanId;
  let destinationAgencyHumanId;
  if (ANCESTOR_KINSHIP_RELATIONSHIPS.includes(kinshipRelationshipForDB)) {
    sourceAgencyHumanId = keystoneAgencyHumanId;
    destinationAgencyHumanId = nonKeystoneAgencyHumanId;
  } else if (
    [...DESCENDENT_KINSHIP_RELATIONSHIPS, FICTIVE_KIN].includes(
      kinshipRelationshipForDB
    )
  ) {
    sourceAgencyHumanId = nonKeystoneAgencyHumanId;
    destinationAgencyHumanId = keystoneAgencyHumanId;
  } else {
    const agencyHumanIds = [keystoneAgencyHumanId, nonKeystoneAgencyHumanId];
    sourceAgencyHumanId = min(agencyHumanIds);
    destinationAgencyHumanId = max(agencyHumanIds);
  }
  const parentalLineForDB = parentalLine
    .map(({ value }) => value)
    .sort()
    .join("_and_");

  return {
    ...relationshipDetails,
    relationshipId: id,
    sourceAgencyHumanId,
    destinationAgencyHumanId,
    kinshipRelationship: kinshipRelationshipForDB,
    parentalLine: isEmpty(parentalLineForDB) ? null : parentalLineForDB,
    emotionalRelationshipStatuses: emotionalRelationshipStatuses.map(
      ({ value }) => value
    ),
    lineageType: lineageType.value || null,
    levelsOfSupport: levelsOfSupport.reduce((los, key) => {
      const topLevelKeys = {};
      if (key.match(`^${PLACEMENT}`)) topLevelKeys.placement = null;
      if (key.match(`^${NON_PLACEMENT}`)) topLevelKeys.non_placement = null;
      const keyValue =
        key === OTHER
          ? { [key]: { details: levelsOfSupportOtherDescription } }
          : { [key]: null };

      return {
        ...los,
        ...keyValue,
        ...topLevelKeys,
      };
    }, {}),
  };
};

export const generateEmptyRelationshipForId = (id, keystoneAgencyHumanId) => ({
  keystoneAgencyHumanId: keystoneAgencyHumanId,
  nonKeystoneAgencyHumanId: id,
});
