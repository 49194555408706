import PropTypes from "prop-types";

import T from "@components/T";

import styles from "./ProgressArc.module.scss";

const WIDTH = 100; // 100 as in 100%, this icon scales with its container
const HEIGHT = 100; // 100 as in 100%, this icon scales with its container
const TAU = 2 * Math.PI;
const NINETY_DEG = -TAU / 4;
const FOREGROUND_COLOR = "#1B74E6";
const BACKGROUND_COLOR = "#EEEEEE";
const EPSILON = 0.000000001;

const RADIUS = WIDTH / 2;

const ProgressIcon = ({ progress }) => {
  const percentage = Math.min(progress / 100.0, 0.9999999);
  const angle = TAU * percentage;
  const x2 = RADIUS * Math.cos(NINETY_DEG + angle);
  const y2 = RADIUS * Math.sin(NINETY_DEG + angle);
  const fillMode = percentage < 0.5 ? "0,1" : "1,1";

  const path = [
    `M0,${-RADIUS}`, // Start at the top of the circle
    `A${RADIUS},${RADIUS}`, // Draw a circular arc of radius RADIUS
    "0", // No rotation
    fillMode, // Fill works different once we get past 50%
    `${x2},${y2}`, // Arc to the point computed by the trig above
    "L0,0", // Draw a line back to the center of the circle
    "Z", // Done.
  ].join(" ");

  return (
    <svg
      className={styles.icon}
      viewBox={`0 0 ${WIDTH} ${HEIGHT}`}
      data-testid="progress-arc-partial"
      data-test-progress-number={progress}
    >
      <title>
        <T t="progress_arc.progress_percentage" percentage={progress} />
      </title>
      <circle r="50%" cx="50%" cy="50%" style={{ fill: BACKGROUND_COLOR }} />
      <g transform={`translate(${RADIUS},${RADIUS})`}>
        {percentage > EPSILON && (
          <path
            d={path}
            style={{ fill: FOREGROUND_COLOR, zIndex: 1 }}
            vectorEffect="non-scaling-stroke"
          />
        )}
      </g>
    </svg>
  );
};

ProgressIcon.propTypes = {
  /** The percent completeness of the task this icon represents */
  progress: PropTypes.number,
};

export default ProgressIcon;
