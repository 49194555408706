import { Button, Flex, Modal, Text } from "@heart/components";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";

import { translationWithRoot } from "@components/T";

const { T } = translationWithRoot("admin.applications.change_role");

/**
 * Component to change the primary applicant.
 *
 * @param {Object} props - The component props.
 * @param {string} props.currentRole - The current role of the applicant.
 * @param {boolean} props.mayChangePrimaryApplicant - Flag indicating if the primary applicant can be changed.
 */
const ChangePrimaryApplicant = ({ currentRole, mayChangePrimaryApplicant }) => {
  // State atom to track if the form is submitting
  const [submitting, setSubmitting] = useState(false);

  // State atom to track if the confirmation modal is hidden
  const [confirmationHidden, setConfirmationHidden] = useState(true);

  const formRef = useRef(null);

  return (
    <Flex align="center" row>
      <Text>
        <T t={`roles.${currentRole}`} />
      </Text>
      <If condition={mayChangePrimaryApplicant}>
        <Button
          variant="secondary"
          submitting={submitting}
          onClick={e => {
            e.preventDefault();
            setConfirmationHidden(false);
          }}
        >
          <T t="switch_with_co_applicant" />
        </Button>
        <If condition={!confirmationHidden}>
          <Modal
            hidden={confirmationHidden}
            onCancel={() => setConfirmationHidden(true)}
            onSubmit={() => {
              setSubmitting(true);
              formRef.current.submit();
            }}
            submitting={submitting}
            title={<T t="switch_with_co_applicant" />}
          >
            <T t="confirm_switch_with_co_applicant" />
          </Modal>
        </If>
      </If>
    </Flex>
  );
};

ChangePrimaryApplicant.propTypes = {
  currentRole: PropTypes.string.isRequired,
  // csrfToken: PropTypes.string.isRequired,
  // applicationId: PropTypes.number.isRequired,
  mayChangePrimaryApplicant: PropTypes.bool.isRequired,
};

export default ChangePrimaryApplicant;
