import _, { pickBy } from "lodash";
import { useCallback, useEffect, useState } from "react";

/*
 * useBintiForm is a combination of useState and useEffect that is specialized for
 * handling form state. This is intended to be used with useQuery to maintain a frontend
 * copy of state that keeps updated with useQuery's updates.
 *
 * Example usage:
 *
 * const { data } = useQuery(SomeQuery);
 * const { saveData } = useMutation(SomeMutation)
 * const { formState, setFormAttribute } = useBintiForm(data);
 * ...
 * <SurfaceForm onSubmit={preventDefault(() => saveData({ variables: formState }))}>
 * }>
 *   <InputText value={formState.name} onChange={setFormAttribute("name")} />
 * ...
 *
 * Note: Do NOT pass a literal into this hook. For example: `useBintiForm({})`.
 *       This will cause an infinite loop because of the useEffect in this hook!
 *
 */

/**
 * Custom hook to manage form state.
 *
 * @param {Object} value - The initial value for the form state.
 * @param {Object} [options] - Optional parameters.
 * @param {Object} [options.initialValue] - The initial value to use if provided.
 */
export default (value, { initialValue } = {}) => {
  const [formState, setFormState] = useState(initialValue || value || {});

  useEffect(
    () =>
      setFormState(fs => {
        const filteredValue = _.omit(value, "__typename");
        return {
          ...fs,
          ...pickBy(filteredValue, x => x !== undefined && x !== null),
        };
      }),
    [value]
  );

  const setFormAttribute = useCallback(
    key => val => setFormState(prevState => ({ ...prevState, [key]: val })),
    []
  );

  return { formState, setFormAttribute, setFormState };
};
