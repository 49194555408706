import { compact } from "lodash";

// helper to autopopulate the slug of something based on its name and agency
$(function () {
  const $nameInput = $(".js-sluggy-name:first");
  const $agencyInput = $(".js-sluggy-agency");
  const $slugInput = $(".js-sluggy-slug");
  let slugManuallyEntered = false;

  function sluggify(parts) {
    return compact(parts)
      .join("-")
      .toLowerCase()
      .replace(/  */g, "-")
      .replace(/'/g, "-") // o'toole -> o-toole
      .replace(/[^-a-zA-Z0-9]/g, "");
  }

  function updateSlug() {
    const selectedAgency = $agencyInput.find("option:selected").text();
    if (!slugManuallyEntered) {
      $slugInput.val(sluggify([selectedAgency, $nameInput.val()]));
    }
  }

  if ($slugInput.length === 1 && $slugInput[0].defaultValue === "") {
    $nameInput.on("change", updateSlug);
    $agencyInput.on("change", updateSlug);
  }

  $slugInput.on("change", function () {
    slugManuallyEntered = true;
  });
});
