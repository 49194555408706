import classNames from "classnames";
import PropTypes from "prop-types";

import completedCheckmark from "@sprockets/images/stage_icons/icon-completed-checkmark.svg";

import styles from "./ProgressArc.module.scss";
import ProgressIcon from "./ProgressIcon";

const ProgressArc = ({ progress, progressIncomplete, tableIcon }) => {
  let progressNumber = progress;

  if (typeof progress === "string") {
    progressNumber = parseInt(progress, 10);
  }

  return (
    <div className={tableIcon ? "table-icon" : styles.container}>
      {progressNumber === 100 && !progressIncomplete ? (
        <img
          className={classNames("svg-checkmark", styles.icon)}
          src={completedCheckmark}
          alt="Completed checkmark"
          data-testid="progress-arc-complete"
        />
      ) : (
        <ProgressIcon className={styles.icon} progress={progressNumber} />
      )}
    </div>
  );
};

ProgressArc.propTypes = {
  /** The percent completeness of the task this icon represents */
  progress: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  /** Indicates wether task is considered complete. There are times when progress is 100%
   *  but the task isn't "complete". In those cases we display a full blue circle.
   */
  progressIncomplete: PropTypes.bool,
  /** Applies the styles needed for displaying this icon in a table. Makes the icon
   *  smaller and centers it.
   */
  tableIcon: PropTypes.bool,
};

export default ProgressArc;
